import React, { useState } from 'react';
import { CrmItemProfile } from './CrmItemProfile';
import { countryName, datetimeFormat } from '../../../../utils/formatter';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { Button, Collapse, Menu, MenuItem, Position } from '@blueprintjs/core';
import { TimeAgo } from '../../Layout/TimeAgo';
import { CrmItemAddress } from './CrmItemAddress';
import { CrmItemTags } from './CrmItemTags';
import { createLink } from '../../../../utils/createLink';
export function CrmItem(props) {
    const item = props.result;
    const [tags, setTags] = useState(null);
    const handleTagsChange = (newTags) => {
        props.updateTagsCallback(item.id, newTags);
        setTags(newTags);
    };
    let details = {
        mainName: item.email,
        subName: [],
        picture: item.picture
            ? '/serve/' + item.picture
            : 'https://www.gravatar.com/avatar/' + item.email_hash + '?d=mp&s=64',
    };
    if (item.profile_first_name || item.profile_last_name) {
        details.mainName = [item.profile_first_name, item.profile_last_name].filter((v) => !!v).join(' ');
        details.subName.push(React.createElement("a", { href: `mailto:${item.email}`, target: "_blank", className: "crm-search-results-item-profile-extradata", key: "email" },
            React.createElement("i", { className: "far fa-envelope" }),
            item.email));
    }
    if (item.profile_job_title || item.profile_company) {
        let label = [item.profile_job_title, item.profile_company].filter((v) => !!v);
        details.subName.push(React.createElement("span", { className: "crm-search-results-item-profile-extradata", key: "job" },
            React.createElement("i", { className: "far fa-building" }),
            label.join(', ')));
    }
    if (item.profile_age) {
        details.subName.push(React.createElement("span", { className: "crm-search-results-item-profile-extradata", key: "age" },
            React.createElement("i", { className: "far fa-birthday-cake" }),
            item.profile_age,
            " ",
            props.itemLabels.age));
    }
    // Select first non-empty location
    const locationPriority = [
        item.area_zip_code_name,
        item.address_zip_code,
        item.area_district_name,
        item.area_province_name,
        item.address_country ? countryName(item.address_country) : '',
        item.area_country_code ? countryName(item.area_country_code) : '',
    ];
    return (React.createElement("div", { className: "crm-search-results-item" },
        React.createElement("div", { className: "crm-search-results-item-details", onClick: props.onClick },
            React.createElement("div", { className: "crm-search-results-item-picture" },
                React.createElement("a", { href: createLink(props.links.view, { '-uuid-': item.id }), onClick: (e) => e.stopPropagation() },
                    React.createElement("img", { src: details.picture }))),
            React.createElement("div", { className: "crm-search-results-item-name" },
                React.createElement("div", null,
                    React.createElement("h4", { className: "crm-search-results-item-mainname" },
                        React.createElement("a", { href: createLink(props.links.view, { '-uuid-': item.id }), onClick: (e) => e.stopPropagation() }, details.mainName)),
                    React.createElement("div", { className: "crm-search-results-item-subname" }, details.subName))),
            React.createElement("div", { className: "crm-search-results-item-location" },
                React.createElement("div", null,
                    React.createElement(Tooltip2, { content: React.createElement(CrmItemAddress, { item: item }), position: Position.TOP },
                        React.createElement("div", null,
                            locationPriority.reduce((prev, value) => (!prev && value ? value : prev)),
                            item.area_country_code ? React.createElement("span", { className: 'fi fi-' + item.area_country_code }) : '')))),
            React.createElement("div", { className: "crm-search-results-item-activity" },
                React.createElement("div", null,
                    React.createElement(Tooltip2, { content: datetimeFormat(item.created_at), position: Position.TOP },
                        React.createElement("div", null,
                            React.createElement(TimeAgo, { date: item.created_at }))))),
            React.createElement("div", { className: 'crm-search-results-item-status crm-search-results-item-status-' + item.status, onClick: (e) => e.stopPropagation() },
                React.createElement("div", { className: "crm-search-results-item-status-label" }, props.itemLabels.status[item.status]),
                React.createElement("div", { className: "crm-search-results-item-status-action" },
                    React.createElement(Popover2, { content: React.createElement(CrmItemMenu, { result: item, links: props.links, labels: props.itemLabels }), position: "bottom-right" },
                        React.createElement(Button, { outlined: true, large: true, className: "crm-search-results-item-status-button" },
                            React.createElement("i", { className: "far fa-ellipsis-v" })))))),
        React.createElement(CrmItemTags, { isReadOnly: props.isReadOnly, result: item, tags: tags, onTagsChange: handleTagsChange, tagsRegistry: props.tagsRegistry, tagsNamesRegistry: props.tagsNamesRegistry, labels: props.tagsLabels }),
        React.createElement(Collapse, { isOpen: props.isOpen },
            React.createElement(CrmItemProfile, { result: item, links: props.links, labels: props.profileLabels }))));
}
function CrmItemMenu(props) {
    return (React.createElement(Menu, null,
        React.createElement(MenuItem, { text: props.labels.actions.view, href: createLink(props.links.view, { '-uuid-': props.result.id }) }),
        React.createElement(MenuItem, { text: props.labels.actions.edit, href: createLink(props.links.edit, { '-uuid-': props.result.id }) })));
}
