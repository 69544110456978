import React, { useEffect, useState } from 'react';
import { numberFormat } from '../../../../utils/formatter';
import { SlateButton } from '../../Layout/SlateButton';
import { AnchorButton, Button, ButtonGroup, Callout, Dialog, Intent, Menu, MenuItem, ProgressBar, } from '@blueprintjs/core';
import { request } from '../../../../utils/http';
import { SingleTagSelector } from '../../Layout/TagsSelector/SingleTagSelector';
import { Popover2 } from '@blueprintjs/popover2';
export function CrmActions(props) {
    const [exportOpen, setExportOpen] = useState(false);
    const [addTagOpen, setAddTagOpen] = useState(false);
    const [removeTagOpen, setRemoveTagOpen] = useState(false);
    const [removeOpen, setRemoveOpen] = useState(false);
    return (React.createElement("div", { className: "crm-search-results-row" },
        React.createElement("div", { className: "crm-search-results-nbhits" },
            numberFormat(props.nbHits),
            " ",
            ' ' + props.actionsLabels.nbHits),
        React.createElement("div", { className: "crm-search-results-actions-buttons" },
            React.createElement(SlateButton, { text: props.actionsLabels.clear, icon: React.createElement("i", { className: "far fa-undo" }), onClick: props.onResetClick, small: true, minimal: true, className: "mr-1" }),
            React.createElement(Popover2, { content: React.createElement(Menu, null,
                    props.batch.export ? (React.createElement(MenuItem, { text: props.actionsLabels.export, onClick: () => setExportOpen(true) })) : (''),
                    props.batch.addTag ? (React.createElement(MenuItem, { text: props.actionsLabels.addTag, onClick: () => setAddTagOpen(true) })) : (''),
                    props.batch.removeTag ? (React.createElement(MenuItem, { text: props.actionsLabels.removeTag, onClick: () => setRemoveTagOpen(true) })) : (''),
                    props.batch.remove ? (React.createElement(MenuItem, { text: props.actionsLabels.remove, onClick: () => setRemoveOpen(true) })) : ('')), position: "bottom-right" },
                React.createElement(SlateButton, { text: props.actionsLabels.applyLabel, rightIcon: React.createElement("i", { className: "far fa-ellipsis-v" }), small: true, minimal: true }))),
        props.batch.export ? (React.createElement(CrmExportAction, Object.assign({ key: 'action-' + exportOpen, open: exportOpen, onClose: () => setExportOpen(false) }, props))) : (''),
        props.batch.addTag ? (React.createElement(CrmAddTagAction, Object.assign({ key: 'add-tag-' + addTagOpen, open: addTagOpen, onClose: () => setAddTagOpen(false) }, props))) : (''),
        props.batch.removeTag ? (React.createElement(CrmRemoveTagAction, Object.assign({ key: 'remove-tag-' + removeTagOpen, open: removeTagOpen, onClose: () => setRemoveTagOpen(false) }, props))) : (''),
        props.batch.remove ? (React.createElement(CrmRemoveAction, Object.assign({ key: 'remove-' + removeOpen, open: removeOpen, onClose: () => setRemoveOpen(false) }, props))) : ('')));
}
function CrmExportAction(props) {
    const [fileUrl, setFileUrl] = useState(null);
    if (fileUrl) {
        return (React.createElement(Dialog, { isOpen: props.open, canOutsideClickClose: false, canEscapeKeyClose: false },
            React.createElement("div", { className: "crm-search-results-actions-dialog" },
                React.createElement(Callout, { intent: Intent.SUCCESS }, props.actionsLabels.exportSuccess),
                React.createElement("br", null),
                React.createElement(ButtonGroup, null,
                    React.createElement(AnchorButton, { intent: Intent.PRIMARY, href: fileUrl, target: "_blank" }, props.actionsLabels.exportDownload),
                    React.createElement(SlateButton, { onClick: () => props.onClose() }, props.actionsLabels.exportCancel)))));
    }
    return (React.createElement(CrmJobDialog, { nbHits: props.nbHits, open: props.open, confirmButtonText: props.actionsLabels.exportConfirm, cancelButtonText: props.actionsLabels.exportCancel, startingText: props.actionsLabels.exportStarting, inProgressText: props.actionsLabels.exportInProgress, title: props.actionsLabels.exportQuestion, onClose: props.onClose, onFinish: (payload) => {
            setFileUrl(payload.fileUrl);
            props.onActionFinished();
        }, startJob: () => new Promise((resolve) => {
            request('POST', props.batch.export, { data: Object.assign(Object.assign({}, props.createBatchPayload()), { params: {} }) }).then((res) => {
                resolve(res.data.statusUrl);
            });
        }) }));
}
function CrmAddTagAction(props) {
    const [selectedTag, setSelectedTag] = useState(null);
    const [isAdded, setIsAdded] = useState(false);
    if (isAdded) {
        return (React.createElement(Dialog, { isOpen: props.open, canOutsideClickClose: false, canEscapeKeyClose: false },
            React.createElement("div", { className: "crm-search-results-actions-dialog" },
                React.createElement(Callout, { intent: Intent.SUCCESS }, props.actionsLabels.addTagSuccess),
                React.createElement("br", null),
                React.createElement(SlateButton, { onClick: () => props.onClose() }, props.actionsLabels.addTagCancel))));
    }
    return (React.createElement(CrmJobDialog, { nbHits: props.nbHits, open: props.open, confirmButtonText: props.actionsLabels.addTagConfirm, confirmButtonDisabled: selectedTag === null, cancelButtonText: props.actionsLabels.addTagCancel, startingText: props.actionsLabels.addTagStarting, inProgressText: props.actionsLabels.addTagInProgress, title: props.actionsLabels.addTagQuestion, content: React.createElement(React.Fragment, null,
            React.createElement(SingleTagSelector, { tagsRegistry: props.tagsRegistry, placeholder: props.actionsLabels.addTagLabel, noResultsLabel: props.actionsLabels.addTagNoResults, onChange: (tag) => setSelectedTag(tag) }),
            React.createElement("br", null),
            React.createElement("br", null)), onClose: props.onClose, onFinish: () => {
            setIsAdded(true);
            props.onActionFinished();
        }, startJob: () => new Promise((resolve) => {
            const batchRequest = Object.assign(Object.assign({}, props.createBatchPayload()), { params: { tagId: selectedTag.id } });
            request('POST', props.batch.addTag, { data: batchRequest }).then((res) => {
                resolve(res.data.statusUrl);
            });
        }) }));
}
function CrmRemoveTagAction(props) {
    const [selectedTag, setSelectedTag] = useState(null);
    const [isRemoved, setIsRemoved] = useState(false);
    if (isRemoved) {
        return (React.createElement(Dialog, { isOpen: props.open, canOutsideClickClose: false, canEscapeKeyClose: false },
            React.createElement("div", { className: "crm-search-results-actions-dialog" },
                React.createElement(Callout, { intent: Intent.SUCCESS }, props.actionsLabels.removeTagSuccess),
                React.createElement("br", null),
                React.createElement(SlateButton, { onClick: () => props.onClose() }, props.actionsLabels.removeTagCancel))));
    }
    return (React.createElement(CrmJobDialog, { nbHits: props.nbHits, open: props.open, confirmButtonText: props.actionsLabels.removeTagConfirm, confirmButtonDisabled: selectedTag === null, cancelButtonText: props.actionsLabels.removeTagCancel, startingText: props.actionsLabels.removeTagStarting, inProgressText: props.actionsLabels.removeTagInProgress, title: props.actionsLabels.removeTagQuestion, content: React.createElement(React.Fragment, null,
            React.createElement(SingleTagSelector, { tagsRegistry: props.tagsRegistry, placeholder: props.actionsLabels.removeTagLabel, noResultsLabel: props.actionsLabels.removeTagNoResults, onChange: (tag) => setSelectedTag(tag) }),
            React.createElement("br", null),
            React.createElement("br", null)), onClose: props.onClose, onFinish: () => {
            setIsRemoved(true);
            props.onActionFinished();
        }, startJob: () => new Promise((resolve) => {
            const batchRequest = Object.assign(Object.assign({}, props.createBatchPayload()), { params: { tagId: selectedTag.id } });
            request('POST', props.batch.removeTag, { data: batchRequest }).then((res) => {
                resolve(res.data.statusUrl);
            });
        }) }));
}
function CrmRemoveAction(props) {
    const [isRemoved, setIsRemoved] = useState(false);
    if (isRemoved) {
        return (React.createElement(Dialog, { isOpen: props.open, canOutsideClickClose: false, canEscapeKeyClose: false },
            React.createElement("div", { className: "crm-search-results-actions-dialog" },
                React.createElement(Callout, { intent: Intent.SUCCESS }, props.actionsLabels.removeSuccess),
                React.createElement("br", null),
                React.createElement(SlateButton, { onClick: () => props.onClose() }, props.actionsLabels.removeCancel))));
    }
    return (React.createElement(CrmJobDialog, { nbHits: props.nbHits, open: props.open, confirmButtonText: props.actionsLabels.removeConfirm, cancelButtonText: props.actionsLabels.removeCancel, startingText: props.actionsLabels.removeStarting, inProgressText: props.actionsLabels.removeInProgress, title: props.actionsLabels.removeQuestion, onClose: props.onClose, onFinish: () => {
            setIsRemoved(true);
            props.onActionFinished();
        }, startJob: () => new Promise((resolve) => {
            request('POST', props.batch.remove, { data: Object.assign(Object.assign({}, props.createBatchPayload()), { params: {} }) }).then((res) => {
                resolve(res.data.statusUrl);
            });
        }) }));
}
function CrmJobDialog(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [job, setJob] = useState(null);
    let interval = 0;
    const refreshJob = (jobStatusUrl) => {
        request('GET', jobStatusUrl).then((res) => {
            setJob(res.data);
            if (res.data.finished) {
                props.onFinish(res.data.payload);
                interval && clearInterval(interval);
            }
        });
    };
    const handleStartJob = () => {
        setIsLoading(true);
        props.startJob().then((jobStatusUrl) => {
            setTimeout(() => {
                refreshJob(jobStatusUrl);
                interval = window.setInterval(() => refreshJob(jobStatusUrl), 3000);
            }, 1000);
        });
    };
    useEffect(() => {
        return () => {
            interval && clearInterval(interval);
        };
    }, []);
    if (isLoading) {
        return (React.createElement(Dialog, { isOpen: props.open, canOutsideClickClose: false, canEscapeKeyClose: false },
            React.createElement("div", { className: "crm-search-results-actions-dialog" },
                React.createElement("h5", { className: "bp4-heading" }, props.title.replace('%nbHits%', numberFormat(props.nbHits))),
                React.createElement("p", { className: "bp4-text-muted" }, job && job.progress
                    ? props.inProgressText + ' (' + Math.round(job.progress * 100) + '%)'
                    : props.startingText),
                React.createElement(ProgressBar, { intent: Intent.PRIMARY, value: job && job.progress ? job.progress : undefined }),
                React.createElement("br", null),
                React.createElement(SlateButton, { onClick: () => props.onClose() }, props.cancelButtonText))));
    }
    return (React.createElement(Dialog, { isOpen: props.open, canOutsideClickClose: false, canEscapeKeyClose: false },
        React.createElement("div", { className: "crm-search-results-actions-dialog" },
            React.createElement("h5", { className: "bp4-heading" }, props.title.replace('%nbHits%', numberFormat(props.nbHits))),
            React.createElement("div", null, props.content ? props.content : ''),
            React.createElement(ButtonGroup, null,
                React.createElement(Button, { intent: Intent.PRIMARY, disabled: props.confirmButtonDisabled || false, onClick: handleStartJob }, props.confirmButtonText),
                React.createElement(SlateButton, { onClick: () => props.onClose() }, props.cancelButtonText)))));
}
